@import '../../static/css/variable.scss';
@import '../../static/css/mixins.scss';

.ftWrap {
  display: flex;
  flex-direction: column;
  max-width: $layoutInnerWidth;
  padding: 0 32px;
  box-sizing: content-box;
  margin: 0 auto;
  .r1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .logoBox {
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        width: 42px;
        height: 40px;
      }
      .logoText {
        font-size: 36px;
        padding-left: 10px;
        background-image: linear-gradient(to bottom, #DEDBE6,#F7F6F9);
        background-clip: text;
        color: transparent;
      }
    }
    .navLauchApp {
      display: flex;
      align-items: center;
      nav {
        display: flex;
        align-items: center;
        padding-right: 16px;
        .goUrls {
          display: flex;
          align-items: center;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $f14;
          line-height: 1;
          letter-spacing: 0.02em;
          padding: 10px 12px;
          >svg {
            font-size: $f24;
            margin-left: 7px;
          }
        }
      }
      .lauchApp {
        @include btnPrimary;
      }
    }
  }
  .r2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .links {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        background: radial-gradient( $btn-secondaryFg, $btn-secondaryBg);
        width: 32px;
        height: 32px;
        border-radius: 1px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        >svg {
          font-size: 24px;
        }
      }

    }
    .copyright {
      font-size: $f12;
      line-height: 1.5;
      letter-spacing: 0.02em;
      font-weight: 400;
    }
  }
}



@media(max-width:1280px ) {
  .ftWrap {
    .r1 {
      flex-direction: column;
      .logoBox {
        .logoText {
          font-size: 30px;
        }
      }
      .navLauchApp {
        flex-direction: column-reverse;
        nav {
          flex-direction: column;
          padding-right: 0;
        }
        .lauchApp {
          margin-bottom: 24px;
          margin-top: 24px;
        }
      }
    }
    .r2 {
      flex-direction: column;
      .copyright {
        margin-top: 24px;
      }
    }
  }
}

