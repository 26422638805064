// https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap



/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXSFsjlC0V7q7Ag.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXS9sjlC0V7o.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXSFsjlC0V7q7Ag.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXS9sjlC0V7o.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXSFsjlC0V7q7Ag.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXS9sjlC0V7o.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXSFsjlC0V7q7Ag.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXS9sjlC0V7o.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXSFsjlC0V7q7Ag.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXS9sjlC0V7o.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXSFsjlC0V7q7Ag.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXS9sjlC0V7o.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXSFsjlC0V7q7Ag.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXS9sjlC0V7o.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXSFsjlC0V7q7Ag.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXS9sjlC0V7o.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXSFsjlC0V7q7Ag.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/L0x4DF02iFML4hGCyMqgXS9sjlC0V7o.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqrbS1miXK2Z7s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqlbS1miXK2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqrbS1miXK2Z7s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqlbS1miXK2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqrbS1miXK2Z7s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqlbS1miXK2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqrbS1miXK2Z7s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqlbS1miXK2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqrbS1miXK2Z7s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqlbS1miXK2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqrbS1miXK2Z7s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqlbS1miXK2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqrbS1miXK2Z7s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqlbS1miXK2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqrbS1miXK2Z7s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqlbS1miXK2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqrbS1miXK2Z7s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/L0x-DF02iFML4hGCyMqlbS1miXK2.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}