$layoutWidth: 1400px;
$layoutInnerWidth: 1120px;

// 主题颜色
$fg: #1E1B3D;
$bg: #2C2847;

// 按钮状态颜色
$btn-primaryFg: #CCB7F8;
$btn-primaryBg: #B292F4;
$btn-primaryText: #1E1B3D;
$btn-secondaryFg: #4C4577;
$btn-secondaryBg: #413B6F;
$btn-secondaryText: #CCB7F8;

// 字体颜色
$primaryText: #F7F6F9;


// 字体定义规范
$h1: 56px;
$h2: 40px;
$h3: 28px;
$f24: 24px;
$f20: 20px;
$f16: 16px;
$f14: 14px;
$f12: 12px;
